export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}


export const config = {

    apiUrl: 'https://cp.telawh.net/api/',
        apiUrl2: 'https://cp.telawh.net/',


    authRoles: {
        sa: ['SA'], // Only Super Admin has access
        admin: ['SA', 'Admin'], // Only SA & Admin has access
        editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
        user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
        guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
    }
}


//apiUrl: getBaseUrl() + 'api/',
//    apiUrl2: getBaseUrl(),

//apiUrl: 'https://cp.telawh.net/api/',
//    apiUrl2: 'https://cp.telawh.net/',

//apiUrl: 'http://localhost:5161/api/',
//    apiUrl2: 'http://localhost:5161/',